import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

import { LayoutContainer, LayoutHeader, LayoutWrapper } from "../components/layout";
import { userLoggedSelector } from "../state/selectors/sessionSelectors";
import { STORE_ID_DEFAULT } from "../constants/businessValues";
import LoadingContext from "../context/LoadingProvider";
import StoreService from "../services/StoreService";
import CartService from "../services/CartService";
import "../components/card/styles.css";

export const Entrance = () => {
  const { setLoadingValue } = useContext(LoadingContext)
  const userLogged = useSelector(userLoggedSelector);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const selectPersons = async () => {
    setLoadingValue(true)


    // const response = { "code": 0, "msg": "OK", "role": "customer" }

    // if (response.msg === "OK") {
    //   navigation("/verify-entrance", { state: { res: response } });
    // } else {
    //   const response = { "code": 0, "msg": "OK", "role": "customer" }
    //   toast(response.msg);
    // }

    StoreService.enterStore(userLogged.login, STORE_ID_DEFAULT, "2", "in")
      .then((res: any) => {
        const response = JSON.parse(res.result);
        console.log(response)
        if (response.msg === "OK") {
          navigation("/verify-entrance", { state: { res: res.result } });
        } else {
          const response = JSON.parse(res.result);
          toast(response.msg);
        }
      })
      .catch(() => toast("Error al abrir tienda"))
      .finally(() => setLoadingValue(false))
  };

  // Evaluacion principal que activa el get de productos
  useEffect(() => {
    const res: any = CartService.getOrderActive(userLogged.login);
    if (res?.length > 0) {
      navigation("/cart");
    }
  }, [dispatch, navigation, userLogged.login]);

  return (
    <LayoutContainer>
      <LayoutWrapper>
        <LayoutHeader>
          <Stack spacing={2} style={style.stack}>
            <Typography color="white" variant="h6">
              Ingreso a la tienda
            </Typography>

            <Button onClick={selectPersons} style={style.usersAccess} variant="outlined" fullWidth>
              <IconButton>
                <PersonIcon style={style.iconPerson} />
              </IconButton>

              <Typography align="center" color="white" variant="button">
                Ingresar a la tienda
              </Typography>
            </Button>

          </Stack>
          <Button onClick={() => navigation("/")} variant="outlined" style={style.cardBtn} fullWidth>
            VOLVER
          </Button>
        </LayoutHeader>
      </LayoutWrapper>
    </LayoutContainer>
  );
};

const style: any = {
  usersAccess: {
    padding: 3,
    borderRadius: "10px",
    borderColor: "white",
    borderWidth: 1,
    borderStyle: "dashed",
    width: "100%",
  },
  iconPerson: {
    fontSize: 99,
    zIndex: 0,
    textAlign: "center",
    align: "center",
    color: "white",
  },
  stack: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    margin: 18,
  },
  cardBtn: {
    textDecoration: "none",
    boxShadow: "none",
    marginTop: 20,
  },
};

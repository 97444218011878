import { useEffect, useState } from "react";
import { Stack, TextField } from "@mui/material";

export const Expiration = ({ handleChange, values, setInvalidState }: any) => {
  const [resultEvaluate, setResultEvaluate] = useState(false);
  // VALIDACIONES DEL CAMPO FECHA DE VENCIMIENTO DE UNA NUEVA TARJETA
  let year = "2050"; //`${new Date().getFullYear()}`.slice(2);
  let yearStart = year[0];
  let yearEnd = year[1];
  let monthStart = `${new Date().getMonth() + 1}`;

  useEffect(() => {
    let validaDate = new RegExp(
      `^(0[1-9]|1[0-2])/?([${yearStart}-9][${yearEnd}-9])$`
    );
    let monthValidate =
      parseInt(monthStart) < 10
        ? `0[${monthStart}-9]|1[0-2]`
        : `1[${monthStart[1]}-2]`;
    if (values.expirationDate.length === 4) {
      if (values.expirationDate.slice(2) === year) {
        monthValidate =
          parseInt(monthStart) < 10
            ? `0[${monthStart[0]}-9]|1[0-2]`
            : `1[${monthStart[1]}-2]`;

        validaDate = new RegExp(
          `^(${monthValidate})/?([${yearStart}-9][${yearEnd}-9])$`
        );
      }

      setResultEvaluate(validaDate.test(values.expirationDate));
    } else {
      setResultEvaluate(false);
    }
  }, [monthStart, values.expirationDate, year, yearEnd, yearStart]);
  // VALIDACIONES DEL CAMPO FECHA DE VENCIMIENTO DE UNA NUEVA TARJETA

  useEffect(() => {
    if (
      values.expirationDate.length === 4 &&
      values.securityCode.length === 3
    ) {
      setInvalidState(false);
    } else {
      setInvalidState(true);
    }
  }, [values, monthStart, values.expirationDate, year, yearEnd, yearStart, setInvalidState]);

  return (
    <Stack alignItems="center" width={"95%"}>
      <TextField
        required
        fullWidth
        margin="normal"
        id="expirationDate"
        label="Vencimiento"
        name="expirationDate"
        value={values.expirationDate}
        onChange={handleChange("expirationDate")}
        error={!resultEvaluate}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        type="number"
      />
      <TextField
        required
        fullWidth
        id="securityCode"
        label="Código Seguridad"
        name="securityCode"
        value={values.securityCode}
        onChange={handleChange("securityCode")}
        error={values.securityCode.length !== 3}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        type="number"
      />
    </Stack>
  );
};

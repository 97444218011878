import { useEffect } from "react";
import { Box, TextField } from "@mui/material";

type OwnerType = {
  handleChange: (val: string) => () => void
  setInvalidState: (val: boolean) => void
  values: any
}

export const Owner = ({ handleChange, values, setInvalidState }: OwnerType) => {
  useEffect(() => {
    if (values.completeCardName.length > 5) {
      setInvalidState(false);
    } else {
      setInvalidState(true);
    }
  }, [values, setInvalidState]);

  return (
    <Box display="contents" component="form" noValidate sx={{ m: 5 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="completeCardName"
        label="Nombre completo"
        name="completeCardName"
        value={values.completeCardName}
        onChange={handleChange("completeCardName")}
        error={values.completeCardName.length < 5}
      />
    </Box>
  );
};

import RequestService from "./RequestService"
import { AccessStatusParams, EnterStoreResponse } from "./typings/storeTypings"

class StoreService {
    public static async enterStore(
        login: string,
        store_id: string,
        door_id: string,
        type: string
    ): Promise<EnterStoreResponse | Error> {
        const response = await RequestService.post<EnterStoreResponse | Error>('/users/EnterStore', {
            login,
            store_id,
            door_id,
            type,
        })
        return (response instanceof Error) ? new Error("No se puede acceder a la tienda") : response

        // // Para no usar puerta
        // return {
        //     id: null,
        //     jsonrpc: '2.0',
        //     result: { "code": 0, "msg": "OK", "role": "customer" }
        // };
    }

    public static async getAccessStatus(login: string, store_id: string): Promise<any | Error> {
        // const params: AccessStatusParams = { login, store_id }
        // const paramsData = new URLSearchParams(params)
        const response = await RequestService.post<any | Error>('/users/get_transaction', { login, store_id })
        return (response instanceof Error) ? new Error("No se puede obtener el estado de la tienda") : response
    }
}

export default StoreService
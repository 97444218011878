import React from "react";
import { Link, Typography } from "@mui/material";

export const TermsLink = () => {
  return (
    <div>
      <Typography component="p" variant="body1">
        Al registrarse acepta{" "}
        <Link
          href="https://www.minigo.store/terminos-y-condiciones"
          target={"_blank"}
        >
          los términos y condiciones del servicio{" "}
        </Link>
        y con{" "}
        <Link
          href="https://www.minigo.store/politicas-de-privacidad"
          target={"_blank"}
        >
          las políticas de privacidad
        </Link>
      </Typography>
    </div>
  );
};

export const usePrice = (price: any) => {
  let priceFormated: any = 0;
  try {
    let priceStr: any = price.toFixed(2);
    priceStr = priceStr.toString();
    priceFormated = "$" + priceStr.replace(".", ",");
  } catch (error) {
    console.log(error);
  }
  return [priceFormated];
};

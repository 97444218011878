/**
 * Este js se encarga de la gestión del estado propio de las vistas
 **/
import { createSlice } from "@reduxjs/toolkit";

/**
 * CreateSlice es un metodo que recibe un objeto de tres atributos
 * name - es el nombre de la objeto del store
 * initialState - es el estado con el que se inicia el objeto al levantar la applicacion
 * reducers - combina el action y el cambio de state de ese objeto cuando se usa
 */
export const firstLaunchSlice = createSlice({
  name: "firstLaunch",
  initialState: {
    launched: true,
    banners: [],
  },
  reducers: {
    setAppFirstLaunch: (state, action) => {
      state.launched = false;
    },
    cleanAppState: (state, action) => {
      state = state.initialState;
    },
    setAppPromos: (state, action) => {
      state.launched = false;
      state.banners = action.payload;
    },
    setPaymentMethods: (state, action) => {
      state.launched = false;
    },
  },
});

/**
 * Por un lado se exportan los actions asociados al reducer
 * por el otro se exporta el reducer en si mismo
 */
export const {
  setAppFirstLaunch,
  cleanAppState,
  setAppPromos,
  setPaymentMethods,
} = firstLaunchSlice.actions;
export default firstLaunchSlice.reducer;

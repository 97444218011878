import React, { useEffect } from "react";
import {
  Button,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PurchaseSummary() {
  const navigation = useNavigate();
  const { userLogged } = useSelector((state) => state.session);
  const { productsInCart, summaryInCart } = useSelector((state) => state.cart);
  const { cart_quantity, create_date, name } = summaryInCart;

  // const updateQtys = () => {
  //   let result = 0;
  //   productsInCart.forEach((el) => {
  //     result = result + parseInt(el.product_uom_qty);
  //   });
  //   return result;
  // };

  const updateSubtotal = () => {
    let result = 0.0;
    productsInCart.forEach((el) => {
      result =
        result + parseFloat(el.product_uom_qty) * parseFloat(el.list_price);
    });
    return result.toFixed(2);
  };

  const updateDiscounts = () => {
    let result = 0.0;
    productsInCart.forEach((el) => {
      if (el.percent_price > 0) {
        result =
          result + (el.list_price - el.discounted_price) * el.product_uom_qty;
      }
    });
    return result.toFixed(2);
  };

  const updateTotal = () => {
    let result = updateSubtotal() - updateDiscounts();

    return result.toFixed(2);
  };

  useEffect(() => {
    if (!userLogged) {
      navigation("/");
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Stack spacing={1} style={{ alignItems: "center", padding: 8 }}>
        <CheckRoundedIcon color="primary" style={{ fontSize: "50px" }} />
        <Typography variant="h6" color="white">
          Compra realizada con éxito
        </Typography>
        <Typography variant="body1" color="white" textAlign="center">
          Documento no válido como factura
          <br />
          N°: {name} <br />
          Fecha: {create_date}
        </Typography>
      </Stack>
      <LinearProgress variant="determinate" value={100} />

      <TableContainer style={{ maxHeight: "40vh" }}>
        <Table aria-label="spanning table">
          <TableBody style={{ overflow: "scroll" }}>
            {productsInCart.map((row) => {
              const nameProduct =
                row.name.charAt(0) + row.name.slice(1).toLowerCase();
              const priceWNDiscount =
                `Precio u ${row.list_price}` +
                (row.percent_price > 0 ? ` -${row.percent_price} %` : "");
              const price_total =
                row.list_price * row.product_uom_qty -
                row.discount_amount * row.product_uom_qty;

              return (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={row.product_id}
                >
                  <TableCell>
                    {nameProduct}
                    <br />
                    {priceWNDiscount}
                  </TableCell>
                  <TableCell align="center">{row.product_uom_qty}u</TableCell>
                  <TableCell align="right">{price_total}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <LinearProgress variant="determinate" value={100} />

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell size="small" colSpan={3} align="right">
                Cantidad de productos
              </TableCell>
              <TableCell size="small" align="right">
                {cart_quantity}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell size="small" colSpan={3} align="right">
                Subtotal
              </TableCell>
              <TableCell size="small" align="right">
                $ {updateSubtotal()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" colSpan={3} align="right">
                Descuento
              </TableCell>
              <TableCell size="small" align="right">
                $ {updateDiscounts()}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell size="small" colSpan={3} align="right">
                <Typography style={{ fontWeight: "bold" }} color="primary">
                  Total
                </Typography>
              </TableCell>
              <TableCell size="small" align="right">
                <Typography style={{ fontWeight: "bold" }} color="primary">
                  $ {updateTotal()}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell size="small" colSpan={3} align="right">
                Medio de pago
              </TableCell>
              <TableCell size="small" align="right">
                ****
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell size="small" colSpan={3} align="right">
                N° de aprobación
              </TableCell>
              <TableCell size="small" align="right">
                ********
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" colSpan={4} align="center">
                <Typography style={{ fontWeight: "bold" }} color="primary">
                  Gracias por comprar con nosotros!
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="text" color="primary">
        Gracias por ser parte de la experiencia MiniGO
      </Button>
    </div>
  );
}

import React from "react";
import {
  DialogTitle,
  Dialog,
  Typography,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";

export interface IMessageModal {
  Icon?: any;
  type: "text" | "custom";
  text: string[];
  title?: string;
  customComponent?: JSX.Element;
  handleClose: any;
  open: boolean;
  actions: IMessageModalAction[];
}
export interface IMessageModalAction {
  title: string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  onClick: any;
}

export default function MessageModal({
  handleClose,
  open,
  Icon,
  type,
  text,
  title,
  actions,
  customComponent,
}: IMessageModal) {
  return (
    <Dialog
      open={open}
      BackdropProps={{
        transitionDuration: 1000,
        color: "inherit",
        style: { backdropFilter: "blur(15px)" },
      }}
      onClose={(prev: any) => handleClose({ modal: false, ...prev })}
      fullWidth
      style={{ backgroundColor: "#222222" }}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          backgroundColor: "#222222",
          display: "flex",
          flexDirection: "column",
          placeContent: "space-evenly",
          alignItems: "center",
          ...(title || Icon ? { padding: 8 } : {}),
        }}
      >
        {Icon && (
          //@ts-ignore //TODO: fix this
          <Icon color="primary" sx={{ fontSize: 80 }} />
        )}
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "#222222",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          placeContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {type === "text"
          ? text?.map((el, index) => (
              <Typography key={index} variant="subtitle1" sx={{ fontSize: 16 }}>
                {el}
              </Typography>
            ))
          : customComponent}
      </DialogContent>
      <DialogActions
        style={{
          backgroundColor: "#222222",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        {actions.map(({ title, onClick, color }) => (
          <Button onClick={onClick} disableRipple color={color || "primary"}>
            {title}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}

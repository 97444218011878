import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Slices
import sessionSlice from "./actions/sessionSlice";
import firstLaunchSlice from "./actions/firstLaunchSlice";
import variablesSlice from "./actions/variblesSlice";
import deliveryMethodSlice from "./actions/deliveryMethodSlice";
import ecommerceSlice from "./actions/ecommerceSlice";
import cartSlice from "./actions/cartSlice";

const reducers = combineReducers({
  session: sessionSlice,
  firstLaunch: firstLaunchSlice,
  variables: variablesSlice,
  delivery: deliveryMethodSlice,
  ecommerce: ecommerceSlice,
  cart: cartSlice,
});

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

export const NumberCard = ({
  handleChange,
  values,
  setInvalidState,
  setProveedor,
}: any) => {
  const [validNumber, setValidNumber] = useState({ provider: "CARD", state: false });

  useEffect(() => {
    const amex = new RegExp("^3[47][0-9]{13}$");
    const master = new RegExp(
      "^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$"
    );
    const mastercard = new RegExp(
      "^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$"
    );
    const visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
    const dinners = new RegExp("^3(?:0[0-5]|[68][0-9])[0-9]{11}$");

    const validateNumberCard = (number: any) => {
      if (amex.test(number)) {
        setValidNumber({ state: true, provider: "AMEX" });
      } else if (master.test(number)) {
        setValidNumber({ state: true, provider: "MASTERCARD" });
      } else if (mastercard.test(number)) {
        setValidNumber({ state: true, provider: "MASTERCARD" });
      } else if (visa.test(number)) {
        setValidNumber({ state: true, provider: "VISA" });
      } else if (dinners.test(number)) {
        setValidNumber({ state: true, provider: "DINNERS" });
      } else {
        setValidNumber({ state: false, provider: "CARD" });
      }
      setInvalidState(validNumber.state);
    };
    validateNumberCard(values.numberCard);
  }, [values, setInvalidState, validNumber.state, setProveedor]);

  useEffect(() => {
    setInvalidState(!validNumber.state);
    setProveedor(validNumber.provider);
  }, [validNumber, setInvalidState, setProveedor]);

  return (
    <Box component="form" noValidate sx={{ mt: 5 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Tarjeta</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={values?.cardIdentification}
          label="Tarjeta"
          onChange={handleChange("cardIdentification")}
        >
          <MenuItem style={{ color: "black" }} value={1}>
            VISA Crédito
          </MenuItem>
          <MenuItem style={{ color: "black" }} value={30}>
            VISA Débito
          </MenuItem>
          <MenuItem style={{ color: "black" }} value={29}>
            MASTERCARD Crédito
          </MenuItem>
          <MenuItem style={{ color: "black" }} value={31}>
            MASTERCARD Débito
          </MenuItem>
          <MenuItem style={{ color: "black" }} value={32}>
            MAESTRO
          </MenuItem>
        </Select>
      </FormControl>

      <TextField
        margin="normal"
        required
        fullWidth
        id="numberCard"
        label="Número tarjeta"
        name="numberCard"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        type="number"
        value={values.numberCard}
        onChange={handleChange("numberCard")}
        error={!validNumber.state}
      />
    </Box>
  );
};

import React from "react";
import { LogoComponent } from "../LogoComponent";

export const LayoutHeader = ({ children, style }: any) => {
  return (
    <>
      <LogoComponent />
      {children}
    </>
  );
};

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import { persistor, store } from "./state";
import { SpinnerLoader } from "./components";
import { theme } from "./utils/theme";
import {
  Home, Password, PaymentList, PaymentNew, Payments,
  Cart,
  Entrance,
  MessagesControl,
  PurchaseSummary,
} from "./features";
import { LoadingProvider } from "./context/LoadingProvider";

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/password", element: <Password /> },
  { path: "/entrance", element: <Entrance /> },
  { path: "/verify-entrance", element: <MessagesControl /> },
  { path: "/cart", element: <Cart /> },
  { path: "/summary", element: <PurchaseSummary /> },
  {
    path: "/payment", element: <Payments />,
    children: [
      { path: "list", element: <PaymentList /> },
      { path: "new", element: <PaymentNew />, },
    ],
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={<SpinnerLoader />} persistor={persistor}>
          <LoadingProvider>
            <ToastContainer hideProgressBar />
            <RouterProvider router={router} fallbackElement={<SpinnerLoader />} />
          </LoadingProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;

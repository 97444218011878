export const stl = {
  errorStyleToast: {
    backgroundColor: "red",
    color: "white",
    fontWeight: "bold",
  },
};

export const ShStyle = {
  center: { alignItems: "center" },
  content: {
    display: "flex",
    height: "90vh",
    flexDirection: "column",
    justifyContent: "space-evenly",
    margin: "32px",
  },
};


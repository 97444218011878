import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { LayoutContainer, LayoutWrapper } from "..";

export default function SpinnerLoader() {
  return (
    <LayoutContainer>
      <LayoutWrapper>
        <Box sx={{ display: "flex", alignSelf: "center" }}>
          <CircularProgress />
        </Box>
      </LayoutWrapper>
    </LayoutContainer>
  );
}

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import MessageModal from "../components/MessageModal";
import { SpinnerLoader } from "../components";
import StoreService from "../services/StoreService";
import { STORE_ID_DEFAULT } from "../constants/businessValues";

export const MessagesControl = () => {
  let engineInterval: any;
  const navigation = useNavigate();
  const { state } = useLocation();
  const { userLogged } = useSelector((state: any) => state.session);
  const { enterGroup } = useSelector((state: any) => state.cart);

  const [apiMsg, setApiMsg] = useState(false);
  const [, setIconSelected] = useState(() => WarningIcon);
  const [titleSelected, setTitleSelected] = useState("");
  const [descSelected, setDescSelected] = useState([]);
  const [actionsSelected, setActionsSelected] = useState([]);

  const startInterval = () => {
    engineInterval = setInterval(() => accessStatus(), 3000);
  };

  const stopInterval = () => {
    clearInterval(engineInterval);
  };

  // FUNCION QUE PIDE MENSAJES CONSTANTEMENTE SOBRE ESTATUS DE LA PUERTA Y PAGO
  const accessStatus = async () => {
    try {
      const response: any = StoreService.getAccessStatus(userLogged.login, STORE_ID_DEFAULT);

      if (response.result.length > 0) {
        stopInterval();
        const accessStoreResponse = response.result[response.result.length - 1];
        const codeResponse = parseInt(accessStoreResponse.code);

        await configMsgByCode(codeResponse);
      }
    } catch (error) { }
  };

  // Funcion para estructurar modal de notificacion y que accione el usuario
  // abrir flujo acompañantes
  const configMsgByCode = (codeResponse: any) => {
    const elementCode: any = messagesByCodeDoor.find(
      (el) => el.code === codeResponse
    );
    setIconSelected(elementCode.icon);
    setTitleSelected(elementCode.title);
    setDescSelected(elementCode.description);
    setActionsSelected(elementCode.actions);
    setApiMsg(true);
  };

  // const goInStore = () => StoreService.accessStore(userLogged.login, '1', '1', true)
  // const goOutStore = () => StoreService.accessStore(userLogged.login, '1', '1', false)

  const messagesByCodeDoor = [
    {
      code: 14, // Tienda Cerrada
      icon: () => { },
      title: "Tienda Cerrada",
      description: ["Tienda cerrada"],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
            navigation("/");
          },
        },
      ],
    },
    {
      code: 1, // Puerta de entrada ocupada
      icon: () => { },
      title: "Hall de ingreso ocupado",
      description: [
        "El hall de ingreso se encuentra ocupado. Por favor aguarda un instante para continuar.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
            navigation("/");
          },
        },
      ],
    },
    {
      code: 2, // Hay mas de un cliente en el hall. Deben ingresar de a uno
      icon: () => { },
      title: "Salir a la vereda",
      description: [
        "Ha ocurrido un problema, por favor salir del hall de ingreso a la vereda y volver a escanear el QR.",
        "Recordá que al hall se debe ingresar de a una persona a la vez.",
      ],
      actions: [
        {
          title: "Salir a la vereda",
          colorPrimary: true,
          onClick: () => {
            setApiMsg(false);
            startInterval();
          },
        },
      ],
    },
    // {
    //   code: 3, // Cliente ingresa al hall de ingreso y es detectado correctamente
    //   icon: () => { },
    //   title: "Bienvenido a la tienda!",
    //   description: ["Por favor, confirma el ingreso para continuar"],
    //   actions: [
    //     {
    //       title: "Confirmar ingreso",
    //       colorPrimary: true,
    //       onClick: () => {
    //         console.log("confirme");
    //         setApiMsg(false);
    //         // goInStore();
    //         startInterval();
    //       },
    //     },
    //     {
    //       title: "Cancelar",
    //       colorPrimary: false,
    //       onClick: () => {
    //         stopInterval();
    //         // goOutStore();
    //         setApiMsg(false);
    //         console.log("navigation.goBack()");
    //         navigation("/");
    //       },
    //     },
    //   ],
    // },
    {
      code: 4, // Usuario no ingresa o no es detectado en el hall de ingreso
      icon: () => { },
      title: "Usted no ingresó",
      description: ["No se ha detectado el ingreso a la tienda."],
      actions: [
        {
          title: "Volver a intentarlo",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
            navigation("/");
          },
        },
      ],
    },
    {
      code: 5, // Usuario confirmo y no ingresó a la tienda
      icon: () => { },
      title: "Salir del hall de ingreso",
      description: [
        "Por favor, no permanecer en el hall de ingreso.",
        "Una vez confirmado, ingresar a la tienda.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => setApiMsg(false),
        },
      ],
    },
    {
      code: 6, // se debe abrir la puerta 1 indefinidamente hasta que salga
      icon: () => { },
      title: "Error de registración",
      description: [
        "Por favor, salir del hall de ingreso.",
        "Reintente la lectura del QR de la puerta.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
            navigation("/");
          },
        },
      ],
    },
    {
      // abrir la logica a acompañantes con contador
      code: 7, // INGRESO A LA TIENDA / SI ENTRA SOLO VA AL QR / SI VA ACOMPAÑADO MUESTRA ESTO
      icon: () => { },
      title: "Bienvido a MiniGO",
      description: [
        !enterGroup ? "Ahora puedes tomar productos" : "¿Ingresa alguien más?",
      ],
      actions: [
        enterGroup && {
          title: "Si",
          onClick: async () => {
            await setApiMsg(false);
            await StoreService.enterStore(userLogged.login, STORE_ID_DEFAULT, "2", "in")
              .then(() => startInterval())
              .catch((err) => {
                console.error(err);
              });
          },
        },
        {
          title: !enterGroup ? "Entendido" : "No",
          onClick: async () => {
            await setApiMsg(false);
            await stopInterval();
            await navigation("/cart");
          },
        },
      ],
    },
    {
      code: 8, //
      icon: () => { },
      title: "Salir del hall de ingreso",
      description: [
        "Por favor, no permanecer en el hall de ingreso.",
        "Debe retirarse del hall.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
            navigation("/");
          },
        },
      ],
    },
    {
      code: 9, //
      icon: () => { },
      title: "--",
      description: ["No found"],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
            navigation("/");
          },
        },
      ],
    },
    {
      code: 10, // Usuario confirmo y no ingresó a la tienda
      icon: () => { },
      title: "--",
      description: ["Saliste de la tienda"],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
            navigation("/");
          },
        },
      ],
    },
    {
      code: 100, // Usuario confirmo y no ingresó a la tienda
      icon: () => { },
      title: "Pago realizado",
      description: ["El pago fue procesado con éxito"],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
            navigation("/");
          },
        },
      ],
    },
    {
      code: 111, // Usuario confirmo y no ingresó a la tienda
      icon: () => { },
      title: "Usted se ha retirado",
      description: ["Se ha retirado sin productos en la tienda."],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
            navigation("/");
          },
        },
      ],
    },
    {
      code: 12, // multiples grupos en salida
      icon: () => { },
      title: "Volver a la tienda",
      description: [
        "Su grupo debe volver a la tienda.",
        "Solo puede salir un grupo a la vez.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            setApiMsg(false);
          },
        },
      ],
    },
    {
      code: 13, // grupo incompleto
      icon: () => { },
      title: "Grupo incompleto",
      description: [
        "Todos los integrantes de tu grupo deben estar en el hall para avanzar con el pago o salir de la tienda.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            setApiMsg(false);
          },
        },
      ],
    },
  ];

  useEffect(() => {
    console.log("369 - ", state);
    postAccessRequest((state as any)?.res);

    return () => stopInterval();
  }, [state]);

  // Pide acceso a puerta si falla debe salir
  const postAccessRequest = async (response: any) => {
    if (response) {
      const accessStoreResponse = JSON.parse(response);
      const codeResponse = parseInt(accessStoreResponse.code);

      // 0 es el primer acceso OK
      if (codeResponse === 0) {
        startInterval();
      } else if (codeResponse === 14) {
        configMsgByCode(codeResponse);
        stopInterval();
      } else {
        const elementCode: any = messagesByCodeDoor.find(
          (el) => el.code === codeResponse
        );
        setIconSelected(elementCode.icon);
        setTitleSelected(elementCode.title);
        setDescSelected(elementCode.description);
        setActionsSelected(elementCode.actions);
        setApiMsg(true);
      }
    }
  };

  return !apiMsg ? (
    <SpinnerLoader />
  ) : (
    <div>
      <MessageModal
        type="text"
        Icon={() => { }}
        title={titleSelected}
        text={descSelected}
        actions={actionsSelected}
        handleClose={() => setApiMsg(false)}
        open={apiMsg}
      />
    </div>
  );
};

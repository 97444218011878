import React from "react";
import "./styles.css";

const Card = ({ values, proveedor }: any) => {
  const {
    completeCardName = "xxxxx xxxxx",
    numberCard = "XXXX",
    expirationDate = "XX/XX",
    securityCode = "xxx",
  } = values;

  return (
    <>
      <div className="card">
        <div className="card__front card__part">
          <p>{proveedor || "CARD"}</p>
          <p className="card_numer">
            **** **** **** {numberCard.slice(-4) || "XXXX"}
          </p>

          <div className="card__space-75">
            <span className="card__label">Nombre del titular</span>
            <p className="card__info">{completeCardName}</p>
          </div>
          <div className="card__space-25">
            <span className="card__label">Vence</span>
            <p className="card__info">{expirationDate}</p>
          </div>
        </div>

        <div className="card__back card__part">
          <div className="card__black-line"></div>
          <div className="card__back-content">
            {completeCardName}
            <div className="card__secret">
              <p className="card__secret--last">{securityCode || "XXX"}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;

import React, { useEffect } from "react";
import { Box, TextField } from "@mui/material";

type DocumentNumberType = {
  handleChange: (val: string) => () => void
  setInvalidState: (val: boolean) => void
  values: any
}

export const DocumentNumber = ({ handleChange, values, setInvalidState }: DocumentNumberType) => {
  useEffect(() => {
    if (values.completeCardName.length > 7) {
      setInvalidState(false);
    } else {
      setInvalidState(true);
    }
  }, [values, setInvalidState]);

  return (
    <Box display="contents" component="form" noValidate sx={{ m: 5 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="identificationNumber"
        label="Número de documento"
        name="identificationNumber"
        value={values.identificationNumber}
        onChange={handleChange("identificationNumber")}
        error={values.identificationNumber.length < 8}
      />
    </Box>
  );
};

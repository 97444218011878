import { searchPaymentActive } from "../utils/searchPayment";
import RequestService from "./RequestService";
import { PaymentDefaultCardPayload, PaymentMethodPayload, PaymentsMethodsResponse, PaymentsMethodsResponseWithData } from "./typings/paymentsTypings";


class PaymentService {
    public static async getUserPaymentMethods(login: string): Promise<any[] | undefined | Error> {
        const response = await RequestService.post<PaymentsMethodsResponseWithData | Error>('/users/get_payment_cards', { login })
        return (response instanceof Error) ? new Error('Error de Obtener medios de pago') : response.result.data
    }

    public static async updateDefaultPayment(card: PaymentDefaultCardPayload): Promise<string | undefined | Error> {
        const response = await RequestService.patch<PaymentsMethodsResponse | Error>('/users/payment_cards', { ...card })
        return (response instanceof Error) ? new Error('Error de Obtener medios de pago') : response.result.message
    }

    public static async createPaymentCard(card: PaymentMethodPayload): Promise<PaymentsMethodsResponse | Error> {
        const response = await RequestService.post<PaymentsMethodsResponse | Error>('/users/payment_cards', { ...card })
        return response
    }

    public static async getDefaultPaymentMethod(login: string): Promise<string | undefined> {
        let defaultPaymentMethod;
        const data = await PaymentService.getUserPaymentMethods(login)
        if (!(data instanceof Error) && data) { defaultPaymentMethod = searchPaymentActive(data) }
        return defaultPaymentMethod
    }

    public static async deletePaymentMethod(login: string, id: string): Promise<any | Error> {
        const response = await RequestService.delete<any | Error>('/users/payment_cards', { login, id }, true)
        return response
    }

}

export default PaymentService
import {createSlice} from '@reduxjs/toolkit';

export const ecommerceSlice = createSlice({
  name: 'ecommerce',
  initialState: {
    alertWeigthMax: 20000, // Constant Alert
    categories: [], // Categories ecommerce from API
    categorySelected: 0, // Category Selected by user on first page
    couponsAplied: [], // Coupons aplied to cart ecommerce
    mainCategories: [], // Main Categories for first page of ecommerce flow
    productsFounded: [], // Products by search
    productsSelected: [], // Products added to cart
    subCategorySelected: 0, // SubCategory selected for search products on grid products
    totalWeigth: 0, // Sum of the weight of each product selected and quantities
  },
  reducers: {
    setCategoriesSubCat: (state, {payload}) => {
      state.mainCategories = payload.mainCategories;
      state.categories = payload.categories;
    },
    setProductsFounded: (state, action) => {
      state.productsFounded = action.payload;
    },
    setProductsSelected: (state, action) => {
      state.productsSelected = action.payload;
    },
    setTotalWeigth: (state, action) => {
      state.totalWeigth = action.payload;
    },
    cleanEcommerceState: (state, action) => {
      state.categorySelected = 0;
      state.couponsAplied = [];
      state.productsFounded = [];
      state.productsSelected = [];
      state.subCategorySelected = 0;
      state.totalWeigth = 0;
      state.searching = false;
    },

    setStoreSubCategorySelected: (state, action) => {
      state.subCategorySelected = action.payload;
    },
  },
});

/**
 * Por un lado se exportan los actions asociados al reducer
 * por el otro se exporta el reducer en si mismo
 */
export const {
  setCategoriesSubCat,
  cleanEcommerceState,
  setProductsFounded,
  setProductsSelected,
  setTotalWeigth,
  setStoreSubCategorySelected,
} = ecommerceSlice.actions;
export default ecommerceSlice.reducer;

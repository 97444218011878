import RequestService from "./RequestService"
import { CartOrderResponse, CurrentOrderParams, RetryPaymentPayload } from "./typings/CartTypings"

class CartService {
    public static async getOrderActive(login: string): Promise<CartOrderResponse | Error> {
        const params: CurrentOrderParams = { login }
        const paramsData = new URLSearchParams(params)
        const response = await RequestService.get<CartOrderResponse | Error>('/sale_order_cart', paramsData)
        return (response instanceof Error) ? new Error("No se puede obtener el carrito actual") : response
    }

    public static async postLeaveRetryOrder(retryPayload: RetryPaymentPayload): Promise<any | Error> {
        const response = await RequestService.post<any | Error>('/get_message_access_control', retryPayload)
        return (response instanceof Error) ? new Error("Error al reintentar el pago") : response.result
    }

}

export default CartService
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField, Typography } from "@mui/material";

import { LayoutContainer, LayoutWrapper, LogoComponent } from "../components";
import { ButtonMGPrimary } from "../components/buttons";
import { setUserUpdateSession, verifyUserExistAction } from "../state/actions";
import LoadingContext from "../context/LoadingProvider";
import { validation } from "../utils";
import SessionService from "../services/SessionService";
import { emailDetectedSelector, userLoggedSelector } from "../state/selectors/sessionSelectors";

const Home = () => {
  const [values, setValues] = useState({ email: "", remember: false, });

  const { setLoadingValue } = useContext(LoadingContext)
  const emailDetected = useSelector(emailDetectedSelector)
  const userLogged = useSelector(userLoggedSelector)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (prop: string) => (event: any) =>
    setValues({ ...values, [prop]: event.target.value.toLowerCase() });

  const signIn = () => {
    try {
      setLoadingValue(true)
      dispatch(setUserUpdateSession(null));
      SessionService.verifyUserExist(values.email)
        .then((res) => dispatch(verifyUserExistAction({ userExist: res, emailDetected: values.email })))
        .finally(() => setLoadingValue(false))
    } catch (error) { console.error(error) }
  };

  useEffect(() => {
    if (userLogged) {
      navigate("payment/list");
    } else if (emailDetected) {
      navigate("password");
    }
  }, [emailDetected, navigate, userLogged]);

  return (
    <LayoutContainer>
      <LayoutWrapper>
        <>
          <LogoComponent />
          <Typography component="h1" variant="h5">
            Bienvenidos a Minigo
          </Typography>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              autoFocus
              margin="normal"
              required
              fullWidth
              id="email"
              label="Ingrese su email"
              name="email"
              value={values.email}
              autoComplete="email"
              onChange={handleChange("email")}
              error={!validation("email", values.email) && !!values.email}
            />

            <ButtonMGPrimary
              title="Continuar"
              action={signIn}
              disabled={!validation("email", values.email)}
            />
          </Box>
        </>
      </LayoutWrapper>
    </LayoutContainer>
  )
};


export default Home;

import { Button, Typography } from "@mui/material";
import { STORE_ID_DEFAULT } from "../../constants/businessValues";
import { usePrice } from "../../hooks/usePrice";
import CartService from "../../services/CartService";

export const SummaryTotals = ({
  summary,
  paymentCard,
  navigation,
  login,
  retryPayment,
}: {
  summary: any;
  paymentCard: any;
  navigation: any;
  login: any;
  retryPayment: any;
}) => {
  const { amount_total, amount_undiscounted } = summary;

  return (
    <div style={stl.contentSummary}>
      <Typography
        variant="h5"
        color="white"
        style={{ fontWeight: "bold", fontSize: "20px" }}
      >
        Total:
      </Typography>
      <Typography variant="h4" color="white" style={{ fontWeight: "bold" }}>
        {usePrice(amount_total)}
      </Typography>
      <Typography variant="body1" color="white">
        {paymentCard &&
          `Debitaremos de tu tarjeta terminada en *${paymentCard[2].substr(
            paymentCard[2].length - 3
          )}`}
      </Typography>
      <Button
        onClick={() => navigation("/payment/list")}
        variant="text"
        color="primary"
      >
        Cambiar medio de pago
      </Button>
      {/* <Button variant="text" color="primary">
        Solicitar Asistencia
      </Button> */}

      {retryPayment && (
        <>
          <Button
            title="REINTENTAR PAGO"
            style={{ margin: "20px" }}
            onClick={() => {
              CartService.postLeaveRetryOrder({
                userId: login,
                storeCode: STORE_ID_DEFAULT,
                doorId: 4,
                code: '9', // intenta cobrar
                message: "Retry order payment",
              });
            }}
          >
            REINTENTAR PAGO
          </Button>

          <Button
            title="DEVOLVER PRODUCTOS"
            style={{ margin: "20px" }}
            onClick={() =>
              CartService.postLeaveRetryOrder({
                userId: login,
                storeCode: STORE_ID_DEFAULT,
                doorId: 9,
                code: '11', // Devolver productos
                message: "Retry order payment",
              })
            }
          >
            DEVOLVER PRODUCTOS
          </Button>
        </>
      )}
    </div>
  );
};

const stl: any = {
  contentSummary: {
    textAlign: "center",
  },
};

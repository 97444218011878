import "./styles.css";

const FastCard = ({ values, setConfirmSelect }: any) => {
  return (
    <>
      <div
        className={`cardFast ${values[values.length - 1]}`}
        onClick={() => setConfirmSelect({ modal: true, card: values })}
      >
        <div className="contentRow">
          <span className="card_numerFast">TARJETA</span>
          <span className="card_numerFast">
            Vence {values[4]}/{values[5]}
          </span>
        </div>
        <div className="contentRow">
          <span className="card_numerFast">{values[1].toUpperCase()}</span>
          <span className="card_numerFast">**** {values[2].slice(-4)}</span>
        </div>
      </div>
    </>
  );
};

export default FastCard;  

import React from "react";
import { Avatar, Typography } from "@mui/material";
import { usePrice } from "../../hooks/usePrice";

export const ItemProduct = ({ item }: { item: any }) => {
  const {
    name,
    image_link,
    product_name,
    price_unit,
    price_total,
    discount,
    product_uom_qty,
  } = item;

  return (
    <div style={stl.container}>
      <div style={{ marginRight: 10 }}>
        <Avatar
          variant="rounded"
          alt={product_name}
          style={{ height: 80, width: 80 }}
          src={image_link}
        />
      </div>
      <div style={stl.centerFlex}>
        <div>
          <Typography
            sx={{ display: "inline", fontWeight: "bold", fontSize: 16 }}
            component="span"
            variant="body2"
            color="primary"
          >
            {name}
          </Typography>
        </div>
        <div>
          <>
            <Typography
              sx={{ display: "inline", fontSize: 12 }}
              variant="body2"
              color="text.primary"
            >
              {product_name}
            </Typography>

            <Typography variant="body2" style={{ display: "inline-flex" }}>
              Precio unitario: {usePrice(price_unit)}
              {discount ? <div style={stl.discount}>-{discount}%</div> : <></>}
            </Typography>
            <Typography variant="body2">Unidades: {product_uom_qty}</Typography>
          </>
        </div>
      </div>
      <Typography style={stl.price}>{usePrice(price_total)}</Typography>
    </div>
  );
};

const stl: any = {
  container: { display: "flex", flexDirection: "row", margin: "20px 0" },
  centerFlex: { flex: 4 },
  price: {
    textAlign: "right",
    fontSize: 20,
    flex: 1,
  },
  discount: {
    backgroundColor: "red",
    color: "white",
    margin: "0 0 0 0.4em",
    padding: "0 0.4em",
    borderRadius: "0.8em",
  },
};

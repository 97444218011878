import { Outlet } from "react-router-dom";
import { LogoComponent } from "../components";
import { LayoutContainer, LayoutWrapper } from "../components/layout";

const Payments = () => {
  return (
    <LayoutContainer>
      <LayoutWrapper>
        <LogoComponent />
        <Outlet />
      </LayoutWrapper>
    </LayoutContainer>
  );
};

export default Payments;

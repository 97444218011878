import React from "react";

import { ButtonProp } from "../typings";
import { ButtonMG } from "./ButtonsComponents";

export const ButtonMGPrimary = ({ title, action, disabled }: ButtonProp) => {
  return (
    <ButtonMG
      onClick={action}
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      disabled={disabled}
    >
      {title}
    </ButtonMG>
  );
};

import { useContext, useEffect, useState } from "react";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { cleanUserSession } from "../state/actions";
import { userLoggedSelector } from "../state/selectors/sessionSelectors";
import { searchPaymentActive } from "../utils/searchPayment";
import { errorConection } from "../utils";
import { stl } from "../styles/base";
import LoadingContext from "../context/LoadingProvider";
import FastCard from "../components/card/FastCard";
import MessageModal from "../components/MessageModal";
import PaymentService from "../services/PaymentService";

export const initialStateCard = []

const PaymentList = () => {
  const [data, setData] = useState<string[]>([]);
  const [confirmSelect, setConfirmSelect] = useState({ modal: false, card: [] });

  const { setLoadingValue } = useContext(LoadingContext)
  const userLogged = useSelector(userLoggedSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(cleanUserSession());
    navigate("/");
  };

  const getPaymentsList = async () => {
    setLoadingValue(true)
    PaymentService.getUserPaymentMethods(userLogged.login)
      .then(data => {
        if (data instanceof Error) {
          errorConection("TypeError: Network request failed")
        } else if (data) {
          const paymentActive = searchPaymentActive(data)
          !paymentActive && PaymentService.updateDefaultPayment({ id: data[0], login: userLogged.login, state: "disabled" });
          setData(data);
        }
      })
      .finally(() => setLoadingValue(false))
  };

  const setMethodDefault = () => {
    const activeCard = searchPaymentActive(data);
    if (activeCard && confirmSelect && activeCard[0] !== confirmSelect.card[0]) {
      PaymentService.updateDefaultPayment({ id: activeCard[0], login: userLogged.login, state: "disabled" })
        .then(res => (res instanceof Error) && errorConection("Error actualizar Tarjeta"))
    }
    PaymentService.updateDefaultPayment({ id: confirmSelect.card[0], login: userLogged.login, state: "active" })
      .then(res => (res instanceof Error) && errorConection("Error actualizar Tarjeta"))
  };

  const deletePayment = () => {
    PaymentService.deletePaymentMethod(userLogged.login, confirmSelect.card[0])
      .then(res => {
        (res instanceof Error) && errorConection("Error actualizar Tarjeta")
        getPaymentsList()
      })
      .catch(() => toast("Error al eliminar medio de pago", { style: stl.errorStyleToast, }))
      .finally(() => setConfirmSelect({ modal: false, card: initialStateCard }))
  };

  useEffect(() => { getPaymentsList() }, []);

  return (
    <>
      <Stack>
        <Box marginBottom={5}>
          <Typography variant="h5" color="white" align="center">
            Bienvenid@
            <br />
            {userLogged?.name || "Usuari@"}
          </Typography>
        </Box>
        <Box width={"100%"}>
          {!data.length ? (
            <Typography variant="h6" color="white" align="center">
              Parece que no hay tarjetas
            </Typography>
          ) : (
            data.map((values) => (
              <FastCard values={values} setConfirmSelect={setConfirmSelect} />
            ))
          )}
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Link onClick={() => navigate("/payment/new")}>
          <Button variant="contained" fullWidth>
            Adicionar Tarjeta
          </Button>
        </Link>
        {data.length > 0 && (
          <Link onClick={() => navigate("/entrance")}>
            <Button variant="contained" fullWidth>
              IR A COMPRAR
            </Button>
          </Link>
        )}
        <div onClick={logout} style={style.cardBtn}>
          <Button variant="outlined" fullWidth>
            CERRAR SESIÓN
          </Button>
        </div>
      </Stack>

      <MessageModal
        type="text"
        Icon={() => { }}
        title="¿Cambiar medio de pago?"
        text={["Estas por seleccionar tu medio de pago predeterminado"]}
        actions={[
          { title: "Seleccionar", onClick: setMethodDefault },
          { title: "Eliminar", onClick: deletePayment },
        ]}
        handleClose={setConfirmSelect}
        open={confirmSelect.modal}
      />
    </>
  );
};

const style: any = {
  content: {
    display: "flex",
    flexDirection: "column",
    margin: 18,
  },
  cardBtn: {
    textDecoration: "none",
    boxShadow: "none",
  },
};

export default PaymentList;

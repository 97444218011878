import React from "react";
import { Stack } from "@mui/system";
import { ButtonMGPrimary } from "./ButtonMGPrimary";
import { useNavigate } from "react-router-dom";

export default function ButtonsStep({
  createUserAndPayment,
  nextStep,
  previousStep,
  formStep,
  invalidState,
}: any) {
  const navigate = useNavigate();
  return (
    <Stack direction={"row"}>
      {formStep === 0 && (
        <ButtonMGPrimary
          title="Salir"
          action={() => navigate("/payment/list")}
        />
      )}
      {formStep !== 0 && (
        <ButtonMGPrimary
          title="Volver"
          action={previousStep}
          disabled={formStep === 0}
        />
      )}

      {formStep !== 3 && (
        <ButtonMGPrimary
          title="Siguiente"
          action={nextStep}
          disabled={invalidState}
        />
      )}
      {formStep === 3 && (
        <ButtonMGPrimary
          title="Finalizar"
          action={createUserAndPayment}
          disabled={invalidState}
        />
      )}
    </Stack>
  );
}

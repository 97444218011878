import axios, { AxiosError, AxiosRequestConfig } from "axios"
import AuthenticationService from "./AuthenticationService"

export const TEST_MODE = true
export const baseUrl = (TEST_MODE ? process.env.REACT_APP_API_URL_TEST : process.env.REACT_APP_API_URL) || ''

export type RequestPromise<TResponse> = Promise<TResponse | AxiosError<TResponse | TData>> & RequestPromiseExtraProperties

// Define un tipo TData que se utiliza en las solicitudes.
// También define un tipo RequestPromiseExtraProperties que agrega propiedades adicionales a las promesas de solicitud.
type TData = {}
type RequestPromiseExtraProperties = {
    abortController: AbortController
    endpoint: string
}
type HeadersType = {
    Authorization?: string
    "Content-Type": string
}

// La clase RequestService contiene métodos para realizar solicitudes HTTP.
class RequestService {
    public static getBaseUrl() { return baseUrl }

    // Crea un AbortController como una propiedad estática para controlar las solicitudes canceladas.
    public static currentAbortController = new AbortController

    // Método para realizar una solicitud POST.
    public static post<TResponse = {}>(
        endpoint: string,
        data: TData,
        withAuthorization: boolean = false,
        requestBaseUrl: string = baseUrl
    ): Promise<TResponse | AxiosError<TResponse | TData>> {
        // Configura los encabezados de la solicitud principales y por defecto
        let headers: HeadersType = { "Content-Type": "application/json" }

        const abortController = new AbortController();
        RequestService.currentAbortController = abortController

        // Realiza una promesa de autorización si es necesario.
        const authPromise = withAuthorization
            ? AuthenticationService.getAuthenticationToken().then((authorizationToken) => {
                if (!authorizationToken) {
                    window.location.pathname = "/";
                    return Error("Error de autorización")
                }
                headers.Authorization = authorizationToken;
            })
            : Promise.resolve()

        // Crea una solicitud Axios con la configuración apropiada.
        const axiosRequest = authPromise.then(() => {
            const config: AxiosRequestConfig<TData> = {
                method: 'post',
                timeout: 20000,
                url: requestBaseUrl + endpoint,
                headers,
                data,
                signal: abortController.signal
            }
            const response = axios(config)
                .then(res => res.data)
                .catch((err: AxiosError<TResponse, TData>) => err)
            return response
        })

        // Agrega propiedades adicionales a la solicitud y la devuelve como una promesa con propiedades adicionales.
        const request = Object.assign(axiosRequest, {
            abortController,
            endpoint
        }) as RequestPromise<TResponse>;
        return request;
    }

    // Método para realizar una solicitud GET.
    public static get<TResponse = {}>(
        endpoint: string,
        params: URLSearchParams,
        withAuthorization: boolean = false,
        requestBaseUrl: string = baseUrl,
    ): Promise<TResponse | AxiosError<TResponse | TData>> {
        let headers: HeadersType = { "Content-Type": "application/json" }

        const abortController = new AbortController();
        RequestService.currentAbortController = abortController

        // Realiza una promesa de autorización si es necesario.
        const authPromise = withAuthorization
            ? AuthenticationService.getAuthenticationToken().then((authorizationToken) => {
                if (!authorizationToken) {
                    window.location.pathname = "/";
                    return Error("Error de autorización")
                }
                headers.Authorization = authorizationToken;
            })
            : Promise.resolve()

        // Crea una solicitud Axios con la configuración apropiada.
        const axiosRequest = authPromise.then(() => {
            const config: AxiosRequestConfig<URLSearchParams> = {
                method: 'get',
                timeout: 20000,
                url: requestBaseUrl + endpoint,
                headers,
                signal: abortController.signal,
                params
            }
            const response = axios(config)
                .then(res => res.data)
                .catch((err: AxiosError<TResponse, TData>) => err)
            return response
        })

        const request = Object.assign(axiosRequest, {
            abortController,
            endpoint
        }) as RequestPromise<TResponse>;
        return request;
    }

    // Método para realizar una solicitud PATCH.
    public static patch<TResponse = {}>(
        endpoint: string,
        data: TData,
        withAuthorization: boolean = false,
        requestBaseUrl: string = baseUrl
    ): Promise<TResponse | AxiosError<TResponse | TData>> {
        // Configura los encabezados de la solicitud principales y por defecto
        let headers: HeadersType = { "Content-Type": "application/json" }

        const abortController = new AbortController();
        RequestService.currentAbortController = abortController

        // Realiza una promesa de autorización si es necesario.
        const authPromise = withAuthorization
            ? AuthenticationService.getAuthenticationToken().then((authorizationToken) => {
                if (!authorizationToken) {
                    window.location.pathname = "/";
                    return Error("Error de autorización")
                }
                headers.Authorization = authorizationToken;
            })
            : Promise.resolve()

        // Crea una solicitud Axios con la configuración apropiada.
        const axiosRequest = authPromise.then(() => {
            const config: AxiosRequestConfig<TData> = {
                method: 'patch',               // Using 'patch' method
                timeout: 20000,               // Timeout for the request (in milliseconds)
                url: requestBaseUrl + endpoint, // The URL you want to send the request to
                headers,                      // Headers for the request (if you've defined them)
                data,                         // The payload to send with the PATCH request
                signal: abortController.signal
            }
            const response = axios(config)
                .then(res => res.data)
                .catch((err: AxiosError<TResponse, TData>) => err)
            return response
        })

        // Agrega propiedades adicionales a la solicitud y la devuelve como una promesa con propiedades adicionales.
        const request = Object.assign(axiosRequest, {
            abortController,
            endpoint
        }) as RequestPromise<TResponse>;
        return request;
    }

    // Método para realizar una solicitud DELETE.
    public static delete<TResponse = {}>(
        endpoint: string,
        data: TData,
        withAuthorization: boolean = false,
        requestBaseUrl: string = baseUrl
    ): Promise<TResponse | AxiosError<TResponse | TData>> {
        // Configura los encabezados de la solicitud principales y por defecto
        let headers: HeadersType = { "Content-Type": "application/json" }

        const abortController = new AbortController();
        RequestService.currentAbortController = abortController

        // Realiza una promesa de autorización si es necesario.
        const authPromise = withAuthorization
            ? AuthenticationService.getAuthenticationToken().then((authorizationToken) => {
                if (!authorizationToken) {
                    window.location.pathname = "/";
                    return Error("Error de autorización")
                }
                headers.Authorization = authorizationToken;
            })
            : Promise.resolve()

        // Crea una solicitud Axios con la configuración apropiada.
        const axiosRequest = authPromise.then(() => {
            const config: AxiosRequestConfig<TData> = {
                method: 'delete',
                timeout: 20000,
                url: requestBaseUrl + endpoint,
                headers,
                data,
                signal: abortController.signal
            }
            const response = axios(config)
                .then(res => res.data)
                .catch((err: AxiosError<TResponse, TData>) => err)
            return response
        })

        // Agrega propiedades adicionales a la solicitud y la devuelve como una promesa con propiedades adicionales.
        const request = Object.assign(axiosRequest, {
            abortController,
            endpoint
        }) as RequestPromise<TResponse>;
        return request;
    }

}

export default RequestService

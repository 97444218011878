import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, LinearProgress, Button, Stack } from "@mui/material";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import WarningIcon from "@mui/icons-material/Warning";

import { LayoutContainer, LayoutWrapper, MessageModal } from "../components";
import { setLoading, setRetryPayment } from "../state/actions";
import { ItemProduct } from "./cart/ItemProduct";
import { SummaryTotals } from "./cart/SummaryTotals";
import StoreService from "../services/StoreService";
import { STORE_ID_DEFAULT } from "../constants/businessValues";
import { userLoggedSelector } from "../state/selectors/sessionSelectors";
import CartService from "../services/CartService";
import { cartActiveSelector, productsInCartSelector, retryPaymentSelector, summaryInCartSelector } from "../state/selectors/cartSelectors";
import PaymentService from "../services/PaymentService";

export default function Cart() {
  let engineInterval: any;
  const [apiMsg, setApiMsg] = useState(false);
  const [iconSelected, setIconSelected] = useState(() => WarningIcon);
  const [titleSelected, setTitleSelected] = useState("");
  const [descSelected, setDescSelected] = useState([]);
  const [actionsSelected, setActionsSelected] = useState([]);
  const [paymentCard, setPaymentCard] = useState<string>();

  const userLogged = useSelector(userLoggedSelector);
  const productsInCart = useSelector(productsInCartSelector);
  const summaryInCart = useSelector(summaryInCartSelector);
  const retryPayment = useSelector(retryPaymentSelector);
  const cartActive = useSelector(cartActiveSelector);
  const navigation = useNavigate();
  const dispatch = useDispatch();


  const messagesByCodeDoor = [
    {
      code: 0,
      icon: () => WarningIcon,
      title: "Error al procesar el pago",
      description: [
        "No se pudo procesar el cobro con tu medio de pago predeterminado.",
        "Si deseas realizar tu compra en otro momento, por favor devolvé los productos a sus respectivas góndolas.",
      ],
      actions: [
        {
          title: "Reintentar pago",
          colorPrimary: true,
          action: async () => {
            await dispatch(setLoading(true));
            await stopInterval();
            await CartService.postLeaveRetryOrder({
              userId: userLogged.login,
              storeCode: STORE_ID_DEFAULT,
              doorId: 4,
              code: '9',
              message: "Retry order payment",
            }).then(startInterval);
            await dispatch(setLoading(false));
            await setApiMsg(false);
          },
        },
        {
          title: "Cambiar medio de pago",
          colorPrimary: true,
          action: () => {
            dispatch(setRetryPayment());
            stopInterval();
            setApiMsg(false);
            navigation("/");
          },
        },
        {
          title: "Devolver productos",
          colorPrimary: false,
          action: async () => {
            await dispatch(setLoading(true));
            await stopInterval();
            await CartService.postLeaveRetryOrder({
              userId: userLogged.login,
              storeCode: STORE_ID_DEFAULT,
              doorId: 9,
              code: '11',
              message: "Dejar productos para salir",
            });
            await dispatch(setLoading(false));
            await setApiMsg(false);
            await navigation("/");
          },
        },
      ],
    },
    {
      code: 14, // Tienda Cerrada
      icon: () => WarningIcon,
      title: "Tienda Cerrada",
      description: ["Tienda cerrada"],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
          },
        },
      ],
    },
    {
      code: 9, //
      icon: () => WarningIcon,
      title: "--",
      description: ["No found"],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            console.log("navigation.goBack()");
          },
        },
      ],
    },
    {
      code: 100, //
      icon: () => WarningIcon,
      title: "Pago realizado",
      description: ["El pago fue procesado con éxito"],
      actions: [
        {
          title: "Ver resumen de compra",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);

          },
        },
      ],
    },
    {
      code: 111, // Usuario confirmo y no ingresó a la tienda
      icon: () => WarningIcon,
      title: "Usted se ha retirado",
      description: ["Se ha retirado sin productos en la tienda."],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            navigation("/");
          },
        },
      ],
    },
    {
      code: 12, // multiples grupos en salida
      icon: () => WarningIcon,
      title: "Volver a la tienda",
      description: [
        "Su grupo debe volver a la tienda.",
        "Solo puede salir un grupo a la vez.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            setApiMsg(false);
          },
        },
      ],
    },
    {
      code: 13, // grupo incompleto
      icon: () => WarningIcon,
      title: "Grupo incompleto",
      description: [
        "Todos los integrantes de tu grupo deben estar en el hall para avanzar con el pago o salir de la tienda.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            setApiMsg(false);
          },
        },
      ],
    },
  ];

  // Evaluacion principal que activa el get de productos
  const evaluationCart = async () => {
    const res = await CartService.getOrderActive(userLogged.login);
    console.log({ resCart: res })
    return res;
  };

  const startInterval = () => {
    engineInterval = setInterval(() => {
      CartService.getOrderActive(userLogged.login);
      accessStatus();
    }, 2500);
  };

  const stopInterval = () => {
    clearInterval(engineInterval);
  };

  // FUNCION QUE PIDE MENSAJES CONSTANTEMENTE SOBRE ESTATUS DE LA PUERTA Y PAGO
  const accessStatus = async () => {
    try {
      const response = await StoreService.getAccessStatus(userLogged.login, STORE_ID_DEFAULT);
      console.log("accessStatus - ", { response })
      // if ((!(response instanceof Error)) && response.result.length > 0) {
      //   await stopInterval();

      //   const accessStoreResponse = response.result[response.result.length - 1];
      //   const codeResponse = parseInt(accessStoreResponse.code);
      //   // navigation('/retry-payment')

      //   await configMsgByCode(codeResponse);
      // }
    } catch (error) { }
  };

  // Funcion para estructurar modal de notificacion y que accione el usuario
  // abrir flujo acompañantes
  const configMsgByCode = (codeResponse: any) => {
    const elementCode: any = messagesByCodeDoor.find(
      (el) => el.code === codeResponse
    );
    setIconSelected(elementCode.icon);
    setTitleSelected(elementCode.title);
    setDescSelected(elementCode.description);
    setActionsSelected(elementCode.actions);
    setApiMsg(true);
  };

  const cleanMsgCode = () => {
    setIconSelected(() => WarningIcon);
    setTitleSelected("");
    setDescSelected([]);
    setActionsSelected([]);
    setApiMsg(false);
  };


  useEffect(() => {
    if (!userLogged) { navigation("/"); }
  }, [navigation, userLogged]);

  useEffect(() => {
    if (!apiMsg) { cleanMsgCode(); }
  }, [apiMsg, navigation, userLogged]);

  // Evaluo permisos al entrar, al salir limpio el interval
  useEffect(() => {
    PaymentService.getDefaultPaymentMethod(userLogged.login).then(setPaymentCard)
    const cartActiveX: any = evaluationCart();
    if (cartActiveX) { startInterval(); }
    return () => stopInterval();
  }, [evaluationCart, startInterval, stopInterval, userLogged.login]);


  if (!productsInCart.length) {
    return (
      <div style={style.content}>
        <Stack spacing={3} style={{ alignItems: "center" }}>
          <ShoppingCartRoundedIcon
            color="primary"
            style={{ fontSize: "128px" }}
          />
          <Typography
            variant="h4"
            color="white"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {!cartActive
              ? "No tiene un carrito activo"
              : "Tu carrito está vacío"}
          </Typography>
          <Typography variant="h6" color="white" align="center">
            {!cartActive
              ? "Accede correctamente al hall de ingreso para ser identificado."
              : "Comienza tu compra agarrando un producto"}
          </Typography>
          <Button
            style={{ margin: "20px" }}
            onClick={() => {
              dispatch(setLoading(false));
              navigation("/");
            }}
          >
            VOLVER
          </Button>
        </Stack>
        {apiMsg && (
          <div style={style.contentFit}>
            <MessageModal
              type="text"
              Icon={iconSelected}
              title={titleSelected}
              text={descSelected}
              actions={actionsSelected}
              handleClose={() => setApiMsg(false)}
              open={apiMsg}
            />
          </div>
        )}
      </div>
    );
  }

  if (productsInCart.length) {
    return (
      <LayoutContainer>
        <LayoutWrapper>
          <div style={style.contentMain}>
            {productsInCart?.map((el: any) => (
              <ItemProduct key={el.barcode} item={el} />
            ))}
          </div>
          <LinearProgress variant="determinate" value={100} />
          <div style={style.contentFooter}>
            <SummaryTotals
              summary={summaryInCart}
              paymentCard={paymentCard}
              navigation={navigation}
              login={userLogged.login}
              retryPayment={retryPayment}
            />
          </div>
          {apiMsg && (
            <div style={style.contentFit}>
              <MessageModal
                type="text"
                Icon={iconSelected}
                title={titleSelected}
                text={descSelected}
                actions={actionsSelected}
                handleClose={() => setApiMsg(false)}
                open={apiMsg}
              />
            </div>
          )}
        </LayoutWrapper>
      </LayoutContainer>
    );
  }

  return (
    <div style={style.content}>
      <Stack spacing={3} style={{ alignItems: "center" }}>
        <ShoppingCartRoundedIcon color="primary" style={{ fontSize: "128px" }} />
        <Typography variant="h4" color="white" align="center" style={style.bold}>
          Ups algo ocurrió...
        </Typography>
      </Stack>

      {apiMsg && (
        <div style={style.contentFit}>
          <MessageModal
            type="text"
            Icon={iconSelected}
            title={titleSelected}
            text={descSelected}
            actions={actionsSelected}
            handleClose={() => setApiMsg(false)}
            open={apiMsg}
          />
        </div>)}
    </div>
  );
}

const style: any = {
  bold: { fontWeight: "bold" },
  contentMain: {
    height: "100%",
    position: "relative",
    overflow: "auto",
  },
  contentFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "20vh",
  },
  contentFit: {
    height: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100vh",
  },
  containerProducts: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },

  listItems: {
    overflow: "auto",
  },
  contentSummary: {
    textAlign: "center",
  },
};

import { createContext, useState } from "react";
import { SpinnerLoader } from "../components";

type DefaultLoadingContextValuesTypes = {
    loadingValue: boolean,
    setLoadingValue: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultLoadingContextValues: DefaultLoadingContextValuesTypes = {
    loadingValue: false,
    setLoadingValue: () => { }
}

export const LoadingContext = createContext(defaultLoadingContextValues)

const LoadingProvider = (props: React.PropsWithChildren) => {
    const [loadingValue, setLoadingValue] = useState<boolean>(false)
    const value = { loadingValue, setLoadingValue }

    return (
        <LoadingContext.Provider value={value}>
            {loadingValue && <SpinnerLoader />}
            {props.children}
        </LoadingContext.Provider>
    )
}
export { LoadingProvider }
export default LoadingContext
import styled from "@emotion/styled";

export const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  padding: 10px;
  justify-content: center;
`;

export const LayoutWrapper = styled.div`
  display: flex;
  min-width: 300px;
  max-width: 600px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
`;

export const LayoutBox = styled.div``;

import React from "react";
import logo from "../assets/images/minigo_logo.png";

export const LogoComponent = () => {
  return (
    <img
      src={logo}
      alt="logo minigo"
      loading="lazy"
      style={{ maxWidth: 200, width: "40%", margin: 20, alignSelf: "center" }}
    />
  );
};

import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    token: false,
    authenticated: false,
    userLogged: null,
    currentPosition: null,
    newUser: false,
    userExist: false,
    emailDetected: "",
    userDummy: {
      terms_conditions_agreement: true,
      afip_responsability_type_id: 5,
      identification_type: 0,
      address: "Tienda Web",
      state_city: "CABA",
      country_state: "Buenos Aires",
      country: "Argentina",
      description: "Tienda Web",
      mobile: "0012345678",
      avatar: null,
    },
  },
  reducers:
  {
    setUserSession: (state, action) => {
      state.token = true;
      state.authenticated = true;
      state.userLogged = action.payload;
    },
    cleanUserSession: (state) => {
      state.token = false;
      state.authenticated = false;
      state.userLogged = null;
      state.emailDetected = '';
      state.messsage = '';
      state.role = '';
      state.status = '';
    },
    setPosition: (state, action) => {
      state.currentPosition = action.payload;
    },
    setUserUpdateSession: (state, action) => {
      state.userLogged = action.payload;
    },
    setUserUpdateDummySession: (state, action) => {
      state.userDummy = action.payload;
    },
    resetUserSessionDummy: (state) => {
      state.userDummy = {
        terms_conditions_agreement: true,
        afip_responsability_type_id: 5,
        identification_type: 0,
        address: "Tienda Web",
        state_city: "CABA",
        country_state: "Buenos Aires",
        country: "Argentina",
        description: "Tienda Web",
        mobile: "0012345678",
        avatar: null,
      };
    },
    verifyUserExistAction: (state, action) => {
      state.emailDetected = action.payload.emailDetected;
      state.userExist = action.payload.userExist;
    }
  }
})

export const {
  setUserSession,
  cleanUserSession,
  setPosition,
  setUserUpdateSession,
  setUserUpdateDummySession,
  resetUserSessionDummy,
  verifyUserExistAction
} = sessionSlice.actions;
export default sessionSlice.reducer;

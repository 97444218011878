import {createSlice} from '@reduxjs/toolkit';

export const deliveryMethodSlice = createSlice({
  name: 'delivery',
  initialState: {
    methodDeliverySelected: {},
    shop_selected_id: '0001',
  },
  reducers: {
    setMethodDeliverySelected: (state, action) => {
      state.methodDeliverySelected = action.payload;
    },
    setShopSelected: (state, action) => {
      state.shop_selected_id = action.payload;
    },
    cleanDeliveryState: (state, action) => {
      state.methodDeliverySelected = {};
    },
  },
});

/**
 * Por un lado se exportan los actions asociados al reducer
 * por el otro se exporta el reducer en si mismo
 */
export const {setMethodDeliverySelected, cleanDeliveryState, setShopSelected} =
  deliveryMethodSlice.actions;
export default deliveryMethodSlice.reducer;

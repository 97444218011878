import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import { cleanUserSession, resetUserSessionDummy, setUserSession, setUserUpdateDummySession, } from "../state/actions";
import { emailDetectedSelector, userDummySelector, userExistSelector } from "../state/selectors/sessionSelectors";
import { LayoutContainer, LayoutWrapper, LogoComponent, } from "../components";
import { TermsLink } from "../components/TermsLink";
import { ButtonMGPrimary } from "../components/buttons";
import { validation } from "../utils";
import LoadingContext from "../context/LoadingProvider";
import SessionService from "../services/SessionService";

const Password = () => {
  const [values, setValues] = useState({ password: "", typePass: false });

  const { setLoadingValue } = useContext(LoadingContext)
  const userDummy = useSelector(userDummySelector)
  const emailDetected = useSelector(emailDetectedSelector)
  const userExist = useSelector(userExistSelector)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const welcomeMsg = useMemo(() => !userExist ? "Tu primera gran experiencia" : "Gracias por volver a visitarnos", [userExist])

  const handleChange = (prop: string) => (event: any) => setValues({ ...values, [prop]: event.target.value });
  const handleClickShowPassword = () => setValues({ ...values, typePass: !values.typePass });

  const signIn = () => {
    if (!validation("email", emailDetected) || !values.password.length) { return; }

    try {
      setLoadingValue(true)
      SessionService.loginEmailPassword(emailDetected.toLowerCase(), values.password)
        .then((data) => {
          if (data) {
            dispatch(setUserSession(data));
            navigate("/payment/list");
          } else {
            toast("Error de autenticación - No se logró autenticar con las credenciales")
          }
        })
        .finally(() => setLoadingValue(false))
    } catch (error) {
      toast("Error de autenticación - No se logró autenticar con las credenciales");
    }
  };

  const signUp = () => {
    if (!validation("email", emailDetected) || !values.password.length) { return; }
    setLoadingValue(true)

    try {
      dispatch(cleanUserSession());
      dispatch(
        setUserUpdateDummySession({
          ...userDummy,
          login: emailDetected,
          password: values.password,
          terms_conditions_agreement: true,
        })
      );
      setLoadingValue(false)
      navigate("/payment/new");
    } catch (error) { setLoadingValue(false) }
  };

  const handleCleanUserSEssion = () => {
    dispatch(cleanUserSession())
    dispatch(resetUserSessionDummy())
  }

  useEffect(() => {
    if (!emailDetected) {
      navigate("/");
    }
  }, [emailDetected, navigate]);

  return (
    <LayoutContainer>
      <LayoutWrapper>
        <LogoComponent />
        <Typography component="h1" variant="h5">
          {welcomeMsg}
        </Typography>

        <Typography component="h1" variant="body2">
          Email: {emailDetected}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              autoFocus
              label="Password"
              id="outlined-adornment-password"
              type={values.typePass ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end">
                    {values.typePass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>} />
          </FormControl>

          <ButtonMGPrimary
            title={userExist ? "Ingresar" : "Registrarme"}
            action={() => userExist ? signIn() : signUp()} />

          <ButtonMGPrimary
            title="Usar otro email"
            action={handleCleanUserSEssion} />

          <TermsLink />
        </Box>
      </LayoutWrapper>
    </LayoutContainer>
  );
};

export default Password;

import RequestService from "./RequestService"
import { LoginResponse, UserDataType, UserLoggedType, VerifyUserExistParams, VerifyUserExistResponse } from "./typings/SessionTypings"

class SessionService {
    public static async verifyUserExist(email: string): Promise<boolean> {
        const params: VerifyUserExistParams = { login: email, vat: "", identification_type: "DNI", }
        const paramsData = new URLSearchParams(params)
        const response = await RequestService.get<VerifyUserExistResponse | Error>('/users?', paramsData)
        if (response instanceof Error) {
            throw new Error('Error de Verificación')
        }
        return response.messsage === 'Email User already exists!'
    }

    public static async loginEmailPassword(login: string, password: string): Promise<UserLoggedType | undefined> {
        const response = await RequestService.post<LoginResponse | Error>('/users/login', { login, password })
        if (response instanceof Error) {
            throw new Error('Error de Login')
        }
        return response.result.data
    }

    public static async registrarUsuario(userData: UserDataType): Promise<any | Error> {
        const response = await RequestService.post<any | Error>('/users', userData)
        return (response instanceof Error) ? new Error('Error de Login') : response.result.data
    }

    // TODO: Implementar
    public static async postAccountRecovery(userData: UserDataType): Promise<any | Error> {
        const response = await RequestService.post<any | Error>('/users/ResetPassword', userData)
        return (response instanceof Error) ? new Error('Error de Login') : response.result.data
    }


}

export default SessionService


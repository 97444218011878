import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import { Card, FormCard, SpinnerLoader } from "../components";
import { ButtonsStep } from "../components/buttons";
import PaymentService from "../services/PaymentService";
import SessionService from "../services/SessionService";
import { emailDetectedSelector, userDummySelector, userExistSelector } from "../state/selectors/sessionSelectors";
import LoadingContext from "../context/LoadingProvider";

const PaymentNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDummy = useSelector(userDummySelector)
  const emailDetected = useSelector(emailDetectedSelector)
  const userExist = useSelector(userExistSelector)

  const { setLoadingValue } = useContext(LoadingContext)
  const { loading } = useSelector((state: any) => state.variables);
  const [invalidState, setInvalidState] = useState(true);
  const [formStep, setFormStep] = useState(0);
  const [proveedor, setProveedor] = useState("CARD");
  const [values, setValues] = useState({
    cardType: "",
    numberCard: "",
    completeCardName: "",
    expirationDate: "",
    securityCode: "",
    resultEvaluate: "",
    cardIdentification: "",
    identificationNumber: "",
  });

  const createUserAndPayment = () => {
    setLoadingValue(true)
    if (!userExist) {
      registerUser();
    } else {
      createNewPaymentCard();
    }
  };

  const registerUser = async () => {
    SessionService.registrarUsuario({
      name: values.completeCardName,
      vat: values.identificationNumber,
      ...userDummy,
    }).then((res) => {
      createNewPaymentCard()
      if (!res.result) { toast(res.title + ", " + res.message); }
    });
  };

  const createNewPaymentCard = async () => {
    switch (values.cardIdentification) {
      case "1":
      case "29":
        setValues({ ...values, cardType: "credit" });
        break;
      default:
        setValues({ ...values, cardType: "debit" });
        break;
    }

    const method = {
      login: emailDetected,
      name: values.completeCardName,
      card_number: values.numberCard,
      security_code: values.securityCode,
      expiration_month: values.expirationDate.slice(0, 2),
      expiration_year: "20" + values.expirationDate.slice(2, 4),
      card_type: values.cardType === "Crédito" ? "credit" : "debit",
      card_identification: values.cardIdentification,
      state: "disabled",
    };

    try {
      PaymentService.createPaymentCard(method)
        .then((res) => {
          if (res) {
            setLoadingValue(false)
            if (res instanceof Error) {
              throw new Error('Error de Verificación')
            } else {
              if (res.result.message === 'OK') { toast("Medio de pago cargado") }
              navigate("/payment/list");
            }
          } else {
            toast("No se añadió la tarjeta, Por favor, verifique que todos los datos estén completos.");
          }
        });
    } catch (error) {
      toast("No se añadió la tarjeta, Por favor, verifique que todos los datos estén completos.");
    }
  };

  const nextStep = useCallback(() => {
    setFormStep(formStep + 1);
  }, [formStep]);

  const previousStep = useCallback(() => {
    setFormStep(formStep - 1);
  }, [formStep]);

  const handleChange = (prop: string) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return loading ? (
    <SpinnerLoader />
  ) : (
    <>
      <Typography component="h1" variant="subtitle1">
        Ingrese{" "}
        {formStep === 0
          ? "Tipo y Número de tarjeta"
          : formStep === 1
            ? "el Titular"
            : formStep === 2
              ? "el Vencimiento y Código de Seguridad"
              : "el Número de Documento"}
      </Typography>

      <Card values={values} proveedor={proveedor} />

      <FormCard {...{ values, formStep, handleChange, setInvalidState, setProveedor }} />
      <div>
        <ButtonsStep
          {...{
            nextStep,
            previousStep,
            formStep,
            invalidState,
            createUserAndPayment,
          }}
        />
      </div>
    </>
  );
};

export default PaymentNew;
